import api from "!../../../../../../../tmp/drone/cache/894801b449e24b8796323a38ee3eb50bc2b06014a9ecb2a246be8ab0db498d58/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../tmp/drone/cache/894801b449e24b8796323a38ee3eb50bc2b06014a9ecb2a246be8ab0db498d58/node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!./flag-icons.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};